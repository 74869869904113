import React, { useEffect, useState } from 'react';
import { MdClose } from 'react-icons/md';
import axios from 'axios'
// import socketio from "../../socket";
import './index.css'
import { useDispatch } from 'react-redux';
import { setBotResponseEducation, setEducationMessages } from '../../../redux/authSlice';

function GraduationComp({setShowEducationComponent }) {
  const [selectedPrograms, setSelectedPrograms] = useState([]);

  const [universities, setUniversities] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState("");
  const [programs, setPrograms] = useState([]);

  const dispatch = useDispatch();

  const handleProgramChange = (program) => {
    setSelectedPrograms((prev) =>
      prev.includes(program)
        ? prev.filter((p) => p !== program)
        : [...prev, program]
    );
  };



  const searchEducation = async () => {
    setShowEducationComponent(false);
    if (!selectedUniversity || selectedPrograms.length === 0) {
      alert("Please select a university and at least one program.");
      return;
    }

    // Construct query parameters
    const universityParam = encodeURIComponent(selectedUniversity);
    const degreesParam = selectedPrograms.map(encodeURIComponent).join(',');

    // Replace "%20" with a space and format the degrees list
    const formattedDegrees = degreesParam.replace(/%20/g, " ").split(",").join(", ");

    const searchPrompt = `Search ${formattedDegrees} programs at ${universityParam} university.`;
    dispatch(setEducationMessages(searchPrompt));

    const apiUrl = `https://api.susan.fi/api/degree/programs?university=${universityParam}&degrees=${degreesParam}&page=1&limit=10`;

    try {
      const response = await axios.get(apiUrl, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      dispatch(setBotResponseEducation(response.data));

      console.log("API Response:", response.data);


    } catch (error) {
      console.error("API Request Failed:", error);
    }
  };

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get("https://api.susan.fi/api/degree/program-counts");
        const data = response.data;
        setUniversities(Object.keys(data));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchPrograms();
  }, []);

  useEffect(() => {
    const fetchProgramsForUniversity = async () => {
      if (selectedUniversity) {
        try {
          const response = await axios.get("https://api.susan.fi/api/degree/program-counts");
          const data = response.data;
          setPrograms(Object.entries(data[selectedUniversity] || {}));
        } catch (error) {
          console.error("Error fetching program data:", error);
        }
      } else {
        setPrograms([]);
      }
    };
    fetchProgramsForUniversity();
  }, [selectedUniversity]);

  return (
    <div className="feedback-panel" style={{ backgroundColor: "#181c35" }}>
      <div className="feedback-header">
        <button className="close-btn p-2" onClick={() => setShowEducationComponent(false)}>
          <MdClose />
        </button>
      </div>
    <div className="job-form-container">
      <label className="job-form-label">Select University</label>
      <select
        className="job-form-input"
        value={selectedUniversity}
          onChange={(e) => setSelectedUniversity(e.target.value)}
          
      >
        <option value="">Select a university</option>
        {universities.map((university) => (
          <option key={university} value={university}>{university}</option>
        ))}
      </select>

      {selectedUniversity && (
        <>
          <label className="job-form-label">Select Program</label>
          <div>
            {programs.map(([program, count]) => (
              <label key={program} className="checkbox-label">
                <input
                  type="checkbox"
                  value={program}
                  checked={selectedPrograms.includes(program)}
                  onChange={() => handleProgramChange(program)}
                />
                {program}<span style={{color:"#999999"}}>({count})</span>
              </label>
            ))}
          </div>
        </>
      )}

      <button className="job-form-button job-form-button-education" onClick={searchEducation}>
        Search Programs
      </button>
    </div>
    </div>
  );
}

export default GraduationComp;
