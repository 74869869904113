import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./header.css";
import Avatar from "../../assets/images/SUSAN_Call_Icon.png";
import Susan from "../../assets/images/susan.png";
import { setDropdownOpen, signOut } from "../../redux/authSlice";

function Index({ visibleAvatar, isDivVisible }) {
  const [seconds, setSeconds] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const dispatch = useDispatch();
  const User = useSelector((state) => state.auth.user);
  const dropdownOpen = useSelector((state) => state.auth.dropdownOpen);
  const dropdownRef = useRef(null);

  useEffect(() => {
    let timer;
    if (visibleAvatar) {
      timer = setInterval(() => {
        setSeconds((prev) => {
          if (prev === 59) {
            setMinutes((min) => min + 1);
            return 0;
          }
          return prev + 1;
        });
      }, 1000);
    } else {
      clearInterval(timer);
      setSeconds(0);
      setMinutes(0);
    }

    return () => clearInterval(timer);
  }, [visibleAvatar]);

    // Detect outside click to close dropdown
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          dispatch(setDropdownOpen(false));
        }
      };
  
      if (dropdownOpen) {
        document.addEventListener("mousedown", handleClickOutside);
      }
  
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [dropdownOpen, dispatch]);

  return (
    <>
      <div className="flex items-center justify-between mb-4 p-4 bg-green shadow-lg rounded-lg header-container">
        {visibleAvatar ? (
          <div
            className="relative pulse-circle bg-blue-250 border border-gray-300 rounded-full "
            style={{ width: "30px", height: "30px", color: "white" }}
          >
            <img
              src={User ? User?.picture : Avatar}
              alt="Description of the avatar"
              className="w-[100%] h-[100%] object-cover rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white"
            />
          </div>
        ) : isDivVisible ? (
          <span
            style={{
              color: "white",
              fontSize: "14px",
              fontWeight: "bold",
            }}
          >
            Ringing...
          </span>
        ) : (
          <>
            <div className="flex items-center space-x-2">
              <img src={Susan} alt="Susan" style={{ width: "30px" }} />
              <span className="text-lg font-bold" style={{ color: "white" }}>
                SUSAN
              </span>
            </div>
            {User !== null && (
              <div className="flex items-center space-x-2" ref={dropdownRef}>
                <img
                  src={User?.picture}
                  alt="Susan"
                  style={{
                    width: "30px",
                    borderRadius: "50%",
                    cursor: "pointer",
                  }}
                  onClick={() => dispatch(setDropdownOpen(!dropdownOpen))}
                />
                {/* Dropdown Menu */}
                {dropdownOpen && (
                  <div className="user-dropdown">
                    <ul>
                      <li onClick={() => console.log("View Resume")}>Resume</li>
                      <li onClick={() => console.log("Settings")}>Settings</li>
                      <li
                        className="logout"
                        onClick={() => {
                          dispatch(signOut());
                        }}
                      >
                        Logout
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </>
        )}
        {visibleAvatar && (
          <span style={{ color: "white" }}>
            {minutes}:{seconds}
          </span>
        )}
        {visibleAvatar && (
          <>
            <div class="pulse-container">
              <div class="pulse-bar1"></div>
              <div class="pulse-bar1"></div>
              <div class="pulse-bar1"></div>
              <div class="pulse-bar1"></div>
              <div class="pulse-bar1"></div>
            </div>
          </>
        )}
      </div>
      {isDivVisible && (
        <>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="flex justify-center items-center relative">
              <div className="pulse-circle bg-blue-100 border border-gray-300 rounded-full w-[200px] h-[200px] absolute" />
              <div className="pulse-circle bg-blue-200 border border-gray-300 rounded-full w-[160px] h-[160px] absolute" />
              <div className="pulse-circle bg-blue-250 border border-gray-300 rounded-full w-[120px] h-[120px] absolute">
                <img
                  src={Avatar}
                  alt="Avatar Icon"
                  className="w-[90%] h-[90%] object-cover rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Index;
