import React, { useEffect, useState, useRef, useReducer } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import socketio from "../socket";
import Trash from "../../assets/images/icons8-trash.svg"
import {
  setBotResponseEducation,
  setEditResume,
  setResumeData,
  setShowDelete,
  setShowResume,
  setSuccessMessage,
  setUserData,
  setViewPrintableResume,
} from "../../redux/authSlice"; // Import Redux action

import {
  IoCopyOutline,
  IoCheckmarkSharp,
  IoShareSocial,
  IoShareSocialOutline,
} from "react-icons/io5";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaWhatsapp,
  FaPaperclip,
  FaUserCircle,
  FaEyeSlash,
  FaEye,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
} from "react-icons/fa";
import {
  AiOutlineLike,
  AiFillLike,
  AiOutlineDislike,
  AiFillDislike,
  AiOutlineArrowDown,
  AiOutlineDownCircle,
  AiOutlineUpCircle,
} from "react-icons/ai";
import { HiOutlineSpeakerWave, HiMiniSpeakerWave } from "react-icons/hi2";
import {
  MdArrowBack,
  MdCalendarToday,
  MdClose,
  MdExpandMore,
  MdSchool,
  MdWork,
  MdWorkOutline,
} from "react-icons/md";
import { marked } from "marked";
import { useGoogleLogin, CodeResponse } from "@react-oauth/google";
import { MdCardGiftcard } from "react-icons/md";
import Google from "../../assets/images/icons8-google.svg";
import Facebook from "../../assets/images/icons8-facebook.svg";
import DownArrow from "../../../src/assets/images/circle-button.png";
import Education from "./Education/index.js"
import "./chat.css";
import socket from "../socket";
import { current } from "@reduxjs/toolkit";

const Message = ({
  type,
  text,
  role,
  timestamp,
  messageId,
  readTextOnClick,
  setActiveButtonState,
  activeButtonState,
  visibleAvatar,
  streamingState,
  setCheckSpeaker,
  checkSpeaker,
  latestMessageId,
  setStreamingContinue,
  onSearchJob,
  socket,
  message
}) => {
  const [activeButton, setActiveButton] = useState({});
  const [visible, setVisible] = useState(type === "user");
  const [showFeedbackPanel, setShowFeedbackPanel] = useState(false);
  const [showApplyJob, setShowApplyJob] = useState(false);
  const [showEducationComponent, setShowEducationComponent] = useState(false);
  const [showApplyType, setShowApplyType] = useState(null);
  const [optionsForJob, setOptionsForJob] = useState(true);
  const [showFeedbackInput, setShowFeedbackInput] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const messageEndRef = useRef(null);
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [title, setTitle] = useState("");
  const [location, setLocation] = useState("");
  const [resume, setResume] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState();
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState("");
  const [uploadProgressMessage, setUploadProgressMessage] = useState("");
  const dispatched = useDispatch();

  const fileInputRef = useRef(null);

  // Redux 
  const showSuccessMessage = useSelector((state) => state.auth.showSuccessMessage);
  const User = useSelector((state) => state.auth);
  const showResume = useSelector((state) => state.auth.showResume);
  const editResume = useSelector((state) => state.auth.editResume);
  const viewPrintableResume = useSelector((state) => state.auth.viewPrintableResume);
  const ShowDelete = useSelector((state) => state.auth.showDelete);
  const ResumeData = useSelector((state) => state.auth.resume);


  const { personalInformation, experiences, education, skills, annotations, summary, totalExperience } =
    ResumeData?.data || {};

  const [editedInfo, setEditedInfo] = useState(personalInformation);
  const [editedExperiences, setEditedExperiences] = useState(experiences);
  const [editedEducation, setEditedEducation] = useState(education);
  const [editedSkills, setEditedSkills] = useState(skills);
  const [editedAnnotations, setEditedAnnotations] = useState([]);


  const handleFileUpload = async (event) => {
    const file = event.target.files[0];

    if (!file) return;
    setSelectedFile(file);

    const formData = new FormData();
    formData.append("resume", file);
    formData.append("userId", User.user?._id || "67da8a993101af67362279f2");
    formData.append("socketId", socket.id);

    try {
      setUploadStatus("Uploading...");
      const res = await axios.post(
        // "http://localhost:5000/api/resume/upload",
        "https://api.susan.fi/api/resume/upload",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setUploadStatus("Upload successful!");
      dispatched(setResumeData({ resume: res.data }));

      // **Clear message after 3 seconds**
      setTimeout(() => {
        setUploadStatus("");
        dispatched(setShowDelete(true));
      }, 3000);

      console.log("File uploaded:", res.data);
    } catch (error) {
      setUploadStatus("Upload failed!");
      console.error("Upload error:", error.response?.data || error.message);
    }
  };

  const socialPlatforms = [
    { name: "Facebook", url: "facebook", icon: <FaFacebook color="#4267B2" /> },
    { name: "Twitter", url: "twitter", icon: <FaTwitter color="#1DA1F2" /> },
    { name: "LinkedIn", url: "linkedin", icon: <FaLinkedin color="#0077B5" /> },
    { name: "WhatsApp", url: "whatsapp", icon: <FaWhatsapp color="#075E54" /> },
  ];

  const shareUrls = (text) => ({
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      text + " " + window.location.href
    )}`,
    twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text
    )}`,
    linkedin: `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      window.location.href
    )}&title=${encodeURIComponent(text)}`,
    whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(
      text + " " + window.location.href
    )}`,
  });
  const urls = shareUrls(text) || {};
  const feedbackOptions = [
    "Irrelevant answer",
    "Too slow to respond",
    "Lacks details",
    "Didn't follow instructions",
    "Confusing or hard to understand",
  ];
  const removeUrls = (text) =>
    text
      .replace(/\[\*(.*?)\*\]\(.*?\)/g, "*$1*") // Keep job titles, remove links
      .replace(/\[(.*?)\]\(.*?\)/g, "$1");
  const reducer = (state, action) => {
    switch (action.type) {
      case "append":
        return state + action.char;
      case "reset":
        return "";
      default:
        return state;
    }
  };

  const [displayedText, dispatch] = useReducer(reducer, "");

  const safeText = typeof text === "string" ? text : "";

  const processedText = safeText.includes(
    "There is no information available regarding"
  )
    ? "I'm sorry, I couldn't find the information you're looking for. Please feel free to reach out directly at Email: contact@TalentBridge.fi or Phone: +358 40 571 0112 for more assistance."
    : safeText;

  const processedTextWithoutUrls = removeUrls(processedText);

  // Format the timestamp
  const formatTime = (timestamp) => {
    try {
      const date = new Date(timestamp);
      if (isNaN(date.getTime())) {
        return "Invalid Date";
      }
      return date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });
    } catch (error) {
      return "Invalid Date";
    }
  };

  const handleCopy = (content) => {
    // Create a temporary element to strip HTML tags
    const tempElement = document.createElement("div");
    tempElement.innerHTML = content; // Set the HTML content
    const plainText = tempElement.innerText || tempElement.textContent; // Extract plain text

    // Copy plain text to the clipboard
    navigator.clipboard.writeText(plainText).then(
      () => {
        console.log("Copied to clipboard:", plainText);
      },
      (error) => {
        console.error("Failed to copy text:", error);
      }
    );
  };

  const handleButtonClick = (messageId, type) => {
    setActiveButton((prev) => {
      const updatedState = { ...prev };

      if (type === "like") {
        updatedState[messageId] = {
          ...prev[messageId],
          like: !prev[messageId]?.like,
          dislike: false,
        };

        // Send feedback to the server
        handleFeedback(
          messageId,
          updatedState[messageId].like ? "like" : "removed_like"
        );
      } else if (type === "dislike") {
        if (!prev[messageId]?.dislike) {
          setShowFeedbackPanel(true);
          setShowFeedbackInput(true);
        } else {
          updatedState[messageId] = { ...prev[messageId], dislike: false };
          // Send null feedback when unselecting dislike
          handleFeedback(messageId, "removed_dislike");
        }
      } else if (type === "copy") {
        updatedState[messageId] = { ...prev[messageId], copy: true };
        handleCopy(role === "assistant" ? styledText : safeText);

        setTimeout(() => {
          setActiveButton((prev) => ({
            ...prev,
            [messageId]: { ...prev[messageId], copy: false },
          }));
        }, 2000);
      } else if (type === "share") {
        updatedState[messageId] = {
          ...prev[messageId],
          share: true,
        };
        setShowSharePopup(false);
      } else {
        updatedState[messageId] = {
          ...prev[messageId],
          [type]: !prev[messageId]?.[type],
        };
        handleFeedback(messageId, type);
      }

      return updatedState;
    });
  };

  const searchFor = (type) => {
    if (type === "job") {
      setShowApplyType(true);
      setOptionsForJob(false);
    }
    if (type === "employee") {
      setShowApplyType(false);
      setOptionsForJob(false);
    }
  };

  const applyForJobs = (messageId, type) => {
    setShowApplyJob(!showApplyJob);
    setShowEducationComponent(false);
    setShowApplyType(null);
    setOptionsForJob(true);
  };

  const searchEducation = (messageId, type) => {
    setShowEducationComponent(!showEducationComponent)
    setShowApplyJob(false);
    setShowApplyJob(false);
    setShowApplyType(null);
    setOptionsForJob(true);
  };

  const handleSpeakClick = (type, messageId) => {
    setCheckSpeaker((prevState) => {
      if (!prevState) prevState = {};

      const isCurrentlyActive = prevState[messageId] === "speak";
      const newState = isCurrentlyActive ? {} : { [messageId]: "speak" };
      return newState;
    });

    setActiveButtonState((prevState) => {
      if (!prevState) prevState = {};

      const isCurrentlyActive = prevState[messageId] === "speak";
      const newState = isCurrentlyActive ? {} : { [messageId]: "speak" };

      readTextOnClick(
        isCurrentlyActive ? "stop" : text,
        isCurrentlyActive ? null : "speak",
        messageId
      );
      return newState;
    });
  };

  useEffect(() => {
    setVisible(true);

    if (streamingState) {
      dispatch({ type: "append", char: processedText.slice(2) });
      return;
    }

    if (role === "assistant") {
      dispatch({ type: "reset" });

      let currentIndex = 2;

      const typeLetter = () => {
        if (currentIndex < processedTextWithoutUrls.length) {
          dispatch({
            type: "append",
            char: processedTextWithoutUrls[currentIndex],
          });
          currentIndex++;
          setTimeout(typeLetter, 4);
        } else {
          // Streaming ends here
          dispatch({ type: "reset" });
          dispatch({ type: "append", char: processedText.slice(2) });
          setStreamingContinue(false);
        }
      };

      setStreamingContinue(true);
      typeLetter();

      return () => {
        dispatch({ type: "reset" });
        setStreamingContinue(false); // Ensure it's reset if effect re-runs
      };
    } else {
      dispatch({ type: "reset" });
      dispatch({ type: "append", char: processedTextWithoutUrls });
      setStreamingContinue(false); // Set false if role is not assistant
    }
  }, [role, processedText]);

  const openPopup = () => {
    setShowPopup(true);
  };
  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (messageEndRef.current) {
      messageEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [
    displayedText,
    showFeedbackPanel,
    showApplyJob,
    showApplyType,
    showResume,
    showEducationComponent
  ]);

  const formattedText = marked(displayedText, {
    renderer: new marked.Renderer(),
    gfm: true,
  });

  const styledText = formattedText.replace(
    /<a href="(.*?)">(.*?)<\/a>/g,
    '<a href="$1" target="_blank" rel="noopener noreferrer" style="color:#2467a3;text-decoration:underline;">$2</a>'
  );

  const handleFeedback = async (messageId, feedback, feedbackMessage) => {
    try {
      await axios.post("https://api.susan.fi/api/feedback", {
        messageId,
        feedback,
        feedbackMessage: feedback === "like" ? null : feedbackMessage,
      });
      setShowFeedbackInput(false);
      setShowFeedbackPanel(false);
      setFeedbackMessage("");
      // Ensure state updates after API call
      setActiveButton((prev) => ({
        ...prev,
        [messageId]: {
          ...prev[messageId],
          dislike: feedback === "dislike",
          like: feedback === "like",
        },
      }));
    } catch (error) {
      console.error("Error updating feedback:", error);
    }
  };

  const searchJob = () => {
    if (title && location) {
      onSearchJob(title, location);
      setShowApplyJob(false);
    }
  };

  const responseGoogle = async (codeResponse) => {
    try {
      if (codeResponse.code) {
        // Send code to backend to fetch user data
        const response = await axios.get(
          `https://api.susan.fi/api/job/signup?code=${codeResponse.code}`
        );
        const googleUserData = response.data;

        setShowPopup(false);

        dispatched(
          setUserData({
            token: googleUserData.token,
            user: googleUserData.user,
          })
        );

        dispatched(setSuccessMessage(true));

        setTimeout(() => {
          dispatched(setSuccessMessage(false));
        }, 5000);

        // Ensure file input is available before triggering click
        setTimeout(() => {
          console.log("fileInputRef.current outer:", fileInputRef.current);
          if (fileInputRef.current) {
            fileInputRef.current.click();
          } else {
            console.log("fileInputRef is null");
          }
        }, 1500);
      }
    } catch (err) {
      console.error("Error during Google login: ", err);
      setShowPopup(true);
    }
  };

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: responseGoogle,
    onError: (err) => console.log("Google login failed", err),
    flow: "auth-code",
  });

  const handleChange = (e, field) => {
    setEditedInfo({ ...editedInfo, [field]: e.target.value });
  };

  const handleExperienceChange = (index, field, value) => {
    const updatedExperiences = [...editedExperiences];
    updatedExperiences[index] = { ...updatedExperiences[index], [field]: value };
    setEditedExperiences(updatedExperiences);
  };

  const handleEducationChange = (index, field, value) => {
    const updatedEducation = [...editedEducation];
    updatedEducation[index] = { ...updatedEducation[index], [field]: value };
    setEditedEducation(updatedEducation);
  };

  const handleSkillsChange = (e) => {
    setEditedSkills(e.target.value.split(",").map(skill => skill.trim()));
  };

  const handleAnnotationChange = (index, field, value) => {
    const updatedAnnotations = [...editedAnnotations];
    updatedAnnotations[index] = { ...updatedAnnotations[index], [field]: value };
    setEditedAnnotations(updatedAnnotations);
  };

  const addExperience = () => {
    setEditedExperiences([
      ...editedExperiences,
      { company: "", startYear: "", endYear: "", jd: "" }
    ]);
  };

  const addEducation = () => {
    setEditedEducation([
      ...editedEducation,
      { school: "", year: "" }
    ]);
  };

  const addSkill = () => {
    setEditedSkills([...editedSkills, ""]);
  };

  const addAnnotation = () => {
    setEditedAnnotations([...editedAnnotations, { url: "" }]);
  };

  const onSave = () => {
    const resumeId = ResumeData?.resumeId || null;

    const updatedResume = {
      personalInformation: {
        name: editedInfo?.name || "",
        email: editedInfo?.email || "",
        phone: editedInfo?.phone || "",
        location: editedInfo?.location || "",
        linkedin: editedInfo?.linkedin || "",
        github: editedInfo?.github || "",
        totalExperience: editedInfo?.totalExperience || "",
      },
      education: editedEducation.map(edu => ({
        degree: edu.degree || "",
        institution: edu.school || "",
        year: edu.year || "",
        status: "Completed" // Update dynamically if needed
      })),
      workExperience: editedExperiences.map(exp => ({
        company: exp.company || "",
        startYear: exp.startYear || "",
        endYear: exp.endYear || "",
        jobDescription: exp.jd || ""
      })),
      skills: editedSkills || [],
      links: editedAnnotations.map(ann => ({
        url: ann.url || ""
      })),
    };

    console.log('updatedResume: ', updatedResume);
    // fetch(`https://susan.fi/api/resume/${resumeId}`, {
    //   method: "PUT",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(updatedResume),
    // })
    //   .then(response => response.json())
    //   .then(data => console.log("Resume updated:", data))
    //   .catch(error => console.error("Error:", error));
  };

  useEffect(() => {
    socketio.on("resumeStatus", (message) => {
      setUploadProgressMessage(message.message)
    });
  })

  const LoadMore = async () => {

    const currentPage = message?.currentPage;

    if (currentPage === currentPage) {
      const newPage = currentPage + 1;
      const apiUrl = `https://api.susan.fi/api/degree/programs?university=${message?.university}&degrees=${message?.degrees}&page=${newPage}&limit=10`;

      try {
        const response = await axios.get(apiUrl, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        dispatched(setBotResponseEducation(response.data));

        // console.log("API Response:", response.data);


      } catch (error) {
        console.error("API Request Failed:", error);
      }
    }

  };
  if (!visible || !processedText) return null;

  return (
    safeText !== "error" &&
    safeText !== "voice" &&
    safeText !== "## Sorry, there was an error processing your request." && (
      <>
        <div className={`message-wrapper ${role}`}>
          <div className={`message-bubble ${role}`}>
            {role === "assistant" ? (
              <>
                <div
                  className="message-content"
                  dangerouslySetInnerHTML={{ __html: styledText }}
                />
                {
                  message?.currentPage && message?.currentPage !== message?.totalPage && (
                    <p style={{ display: "flex", justifyContent: "center", alignItems: "center"}} onClick={LoadMore}>
                      <span className="btn-load-more">Load More  <MdExpandMore size={20} /></span>
                    </p>

                  )
                }
              </>
            ) : (
              <p className="message-content">{safeText}</p>
            )}
          </div>
          <div className={`message-timestamp ${role}`}>
            {formatTime(timestamp)}
          </div>
          <div ref={messageEndRef} />
          {role === "assistant" && (
            <div className={`like-dislike assistant`}>

              <button
                aria-label="send gift"
                style={{ color: "#00a783", cursor: "pointer" }}
                title="Coming Soon"
                onClick={() => setShowComingSoon(!showComingSoon)}
              >
                <MdCardGiftcard />
              </button>

              <button
                aria-label="jobs"
                style={{ color: "#ffff00", cursor: "pointer" }}
                onClick={() => applyForJobs(messageId, "Jobs")}
                title="Jobs"
              >
                <MdWorkOutline />
              </button>

              <button
                aria-label="education"
                style={{ color: "#1b70f3", cursor: "pointer" }}
                onClick={() => searchEducation(messageId, "education")}
                title="Education"
              >
                <MdSchool />
              </button>

              <button
                aria-label="calendar"
                style={{ color: "#8e8f90", cursor: "pointer" }}
                title="Coming Soon"
                onClick={() => setShowComingSoon(!showComingSoon)}
              >
                <MdCalendarToday />
              </button>
              <button
                aria-label="speak message"
                disabled={visibleAvatar}
                onClick={() => handleSpeakClick("speak", messageId)}
              >
                {checkSpeaker?.[messageId] ? (
                  <div class="spinner"></div>
                ) : activeButtonState?.[messageId] === "speak" ? (
                  <HiMiniSpeakerWave />
                ) : (
                  <HiOutlineSpeakerWave />
                )}
              </button>

              <button
                aria-label="Share Message"
                onClick={() => setShowSharePopup(!showSharePopup)}
              >
                {activeButton[messageId]?.share ? (
                  <IoShareSocial />
                ) : (
                  <IoShareSocialOutline />
                )}
              </button>

              <button
                aria-label="Copy message"
                onClick={() => handleButtonClick(messageId, "copy")}
              >
                {activeButton[messageId]?.copy ? (
                  <IoCheckmarkSharp />
                ) : (
                  <IoCopyOutline />
                )}
              </button>

              <button
                aria-label="Like message"
                onClick={() => handleButtonClick(messageId, "like")}
              >
                {activeButton[messageId]?.like ? (
                  <AiFillLike />
                ) : (
                  <AiOutlineLike />
                )}
              </button>

              <button
                aria-label="Dislike message"
                onClick={() => handleButtonClick(messageId, "dislike")}
              >
                {activeButton[messageId]?.dislike ? (
                  <AiFillDislike />
                ) : (
                  <AiOutlineDislike />
                )}
              </button>
            </div>
          )}
          {
            showEducationComponent && (
              <Education showEducationComponent={searchEducation} setShowEducationComponent={setShowEducationComponent} />
            )
          }
          {showComingSoon && (
            <div className="popup">
              <button
                className="close-btn"
                onClick={() => setShowComingSoon(false)}
              >
                ×
              </button>
              <h4>Coming Soon</h4>
            </div>
          )}
          {showFeedbackPanel && (
            <div className="feedback-panel">
              <div className="feedback-header">
                <span>Tell us more:</span>
                <button
                  className="close-btn"
                  onClick={() => setShowFeedbackPanel(false)}
                >
                  <MdClose />
                </button>
              </div>
              <div className="feedback-options">
                {feedbackOptions.map((option, key) => (
                  <button
                    key={key}
                    onClick={() => handleFeedback(messageId, "dislike", option)}
                  >
                    {option}
                  </button>
                ))}
              </div>
              {showFeedbackInput && (
                <div className="feedback-input">
                  <textarea
                    value={feedbackMessage}
                    onChange={(e) => setFeedbackMessage(e.target.value)}
                    placeholder="Enter your feedback..."
                  />
                  <button
                    onClick={() =>
                      handleFeedback(messageId, "dislike", feedbackMessage)
                    }
                    disabled={!feedbackMessage.trim()}
                  >
                    Send
                  </button>
                </div>
              )}
            </div>
          )}
          {showApplyJob && (
            <div
              className="feedback-panel"
              style={{ backgroundColor: "#31312a" }}
            >
              <div className="feedback-header">
                <button
                  className="close-btn p-2"
                  onClick={() => {
                    setShowApplyJob(false);
                    setShowApplyType(null);
                    setOptionsForJob(true);
                  }}
                >
                  <MdClose />
                </button>
              </div>
              {optionsForJob ? (
                <div className="Job-options">
                  <button onClick={() => searchFor("job")}>Find a job</button>
                  <span style={{ color: "yellow" }}>or</span>
                  <button onClick={() => searchFor("employee")}>
                    Find an employee
                  </button>
                </div>
              ) : (
                ""
              )}

              {showApplyType === true && (
                <div className="job-form-container">
                  <div className="job-form-header">
                    <button
                      className="back-btn"
                      onClick={() => {
                        setShowApplyType(null);
                        setOptionsForJob(true);
                      }}
                    >
                      <MdArrowBack size={24} style={{ color: "white" }} />
                    </button>
                  </div>
                  <label className="job-form-label">What type of job?</label>
                  <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    className="job-form-input"
                    placeholder="Enter job title"
                  />

                  <label className="job-form-label">Location:</label>
                  <input
                    type="text"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    className="job-form-input"
                    placeholder="Enter location"
                  />
                  <div
                    className={`${showSuccessMessage || uploadStatus === "Upload successful!" ? "job-form-upload-success" : "job-form-upload"} ${ShowDelete ? "job-form-delete-active" : ""}`}
                    style={{ margin: "15px 0px" }}
                  >
                    {ResumeData ? (
                      <>
                        {uploadStatus === "Upload successful!" && (
                          <p style={{ marginBottom: "10px", fontSize: "14px", color: "#cbca37", textAlign: "center" }}>
                            Your Resume has been uploaded successfully
                          </p>
                        )}

                        <a
                          href="#"
                          style={{
                            color: "#fffe74",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "30px"
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            if (showResume || editResume) {
                              dispatched(setShowResume(false));
                              dispatched(setEditResume(false));
                            } else {
                              dispatched(setShowResume(true));
                            }
                          }}
                        >
                          View & Edit Resume
                          {showResume || editResume ? (
                            <AiOutlineUpCircle
                              size={20}
                              style={{ marginLeft: "5px" }}
                            />
                          ) : (
                            <AiOutlineDownCircle
                              size={20}
                              style={{ marginLeft: "5px" }}
                            />
                          )}
                        </a>

                        {/* Delete Resume Button */}
                        {
                          ShowDelete && (
                            <div style={{ display: "flex", cursor: "pointer", justifyContent: "center", marginTop: "2px" }} onClick={(e) => {
                              e.preventDefault();
                              if (window.confirm("Are you sure you want to delete your resume?")) {
                                dispatched(setResumeData({ resume: '' }));
                                dispatched(setEditResume(false));
                                dispatched(setShowResume(false));
                                dispatched(setShowDelete(false));
                              }
                            }}>

                              <img src={Trash} alt="" style={{ width: "18px", height: "18px" }} />
                              <span style={{ color: "#AF4A4A", marginLeft: "5px", fontSize: "14px" }}>Remove</span>
                            </div>
                            // <a
                            //   href="#"
                            //   style={{ color: "#ff0000", textDecoration: "none", fontSize: "10px", border: "1px solid #f00", padding: "2px 5px", borderRadius: "5px" }}
                            //   onClick={(e) => {
                            //     e.preventDefault();
                            //     if (window.confirm("Are you sure you want to delete your resume?")) {
                            //       dispatched(setResumeData({ resume: '' }));
                            //       dispatched(setEditResume(false));
                            //       dispatched(setShowResume(false));
                            //       dispatched(setShowDelete(false));
                            //     }
                            //   }}
                            // >
                            //   Delete Resume
                            // </a>
                          )
                        }
                      </>
                    ) : (
                      <>
                        {showSuccessMessage && (
                          <p style={{ marginBottom: "10px", fontSize: "14px", color: "#cbca37", textAlign: "center" }}>
                            You have signed in successfully
                          </p>
                        )}
                        {User.user === null ? (
                          <label className="upload-label" onClick={openPopup}>
                            <FaPaperclip
                              className="upload-icon"
                              style={{ marginRight: "5px" }}
                            />
                            Upload Resume
                            <em style={{ marginLeft: "5px" }}>(optional)</em>
                          </label>
                        ) : (
                          uploadStatus === "Uploading..." ? <>
                            <label className="upload-label">
                              <FaPaperclip
                                className="upload-icon"
                                style={{ marginRight: "5px" }}
                              />
                              Uploading...
                            </label>
                            {resume && (
                              <span className="resume-name">{resume.name}</span>
                            )}
                            {uploadStatus === "Uploading..." && <> <span class="spinner"></span> <span style={{ color: "white", fontSize: "10px" }}> {uploadProgressMessage}</span> </>}
                            {uploadStatus === "Upload failed!" && <span className="resume-name" style={{ color: "#f33131" }}>Upload failed. Refresh and try again.</span>}
                          </> :
                            <>
                              <label className="upload-label">
                                <FaPaperclip
                                  className="upload-icon"
                                  style={{ marginRight: "5px" }}
                                />
                                Upload Resume
                                <em style={{ marginLeft: "5px" }}>(optional)</em>
                                <input
                                  type="file"
                                  ref={fileInputRef}
                                  onChange={handleFileUpload}
                                />
                              </label>
                              {resume && (
                                <span className="resume-name">{resume.name}</span>
                              )}
                              {uploadStatus === "Uploading..." && <> <span class="spinner"></span> <span style={{ color: "white", fontSize: "10px" }}> {uploadProgressMessage}</span> </>}
                              {uploadStatus === "Upload failed!" && <span className="resume-name" style={{ color: "#f33131" }}>Upload failed. Refresh and try again.</span>}
                            </>
                        )}
                      </>
                    )}
                  </div>
                  {showResume && (
                    <div className="popup-form-container">
                      <div className="btn-container-resume">
                        <button
                          className="close-btn-popup"
                          onClick={() => dispatched(setShowResume(false))}
                        >
                          <MdClose />
                        </button>
                      </div>
                      <div className="popup-form2">
                        <div
                          className="popup-main-container-body"
                          style={{
                            margin: "auto",
                            fontFamily: "Arial, sans-serif",
                            border: "1px solid #ddd",
                            borderRadius: "10px",
                            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                          }}
                        >
                          {/* Personal Info */}
                          <h1 style={{ textAlign: "center", color: "#333" }}>
                            {personalInformation?.name || "N/A"}
                          </h1>
                          <p style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <FaEnvelope style={{ marginRight: "5px", color: "black" }} />
                            <a href={`mailto:${personalInformation?.email}`} style={{ marginRight: "5px" }}>
                              {personalInformation?.email || "N/A"}
                            </a>
                            | <FaPhone style={{ margin: "0px 5px", color: "black" }} /> {personalInformation?.phone || "N/A"}
                          </p>
                          <p style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <FaMapMarkerAlt style={{ marginRight: "5px", color: "#EA4335" }} /> {personalInformation?.location || "N/A"}
                          </p>

                          {/* Title and Summary */}
                          <h3 style={{ color: "#444", borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
                            Title
                          </h3>
                          <p>{ResumeData?.data?.title || "N/A"}</p>

                          <h3 style={{ color: "#444", borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
                            Summary
                          </h3>
                          {summary ? (
                            <p
                              style={{ lineHeight: "1.6", padding: "0.5rem 0" }}
                              dangerouslySetInnerHTML={{
                                __html: "• " + summary.split("\n").join("<br /><br />• "),
                              }}
                            />
                          ) : (
                            <p>N/A</p>
                          )}

                          {/* Experience */}
                          <h3 style={{ color: "#444", borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
                            Work Experience
                          </h3>
                          {experiences
                            ?.filter((exp) => exp.company || exp.jd)
                            .map((exp, index) => (
                              <div key={index} style={{ marginBottom: "20px" }}>
                                <h4
                                  style={{
                                    color: "#444",
                                    fontWeight: "bold",
                                    marginBottom: "0px",
                                    fontSize: "16px",
                                  }}
                                >
                                  {exp.company && exp.company.trim() ? exp.company : "Company Name Not Provided"}
                                </h4>
                                <p>
                                  <strong>Duration:</strong> {exp?.startYear || "N/A"} - {exp?.endYear || "N/A"}
                                </p>
                                {exp.jd ? (
                                  <p
                                    style={{ lineHeight: "1.6", padding: "0.5rem 0" }}
                                    dangerouslySetInnerHTML={{
                                      __html: "• " + exp.jd.split("\n").join("<br /><br />• "),
                                    }}
                                  />
                                ) : (
                                  <p>N/A</p>
                                )}
                              </div>
                            ))}

                          {/* Education */}
                          <h3 style={{ color: "#444", borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
                            Education
                          </h3>
                          {education?.length > 0 ? (
                            education.map((edu, index) => (
                              <p key={index}>
                                <strong>{edu?.school || "N/A"}</strong> - {edu?.year || "N/A"}
                              </p>
                            ))
                          ) : (
                            <p>No education details provided.</p>
                          )}

                          {/* Skills */}
                          <h3 style={{ color: "#444", borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
                            Skills
                          </h3>
                          {skills?.length > 0 ? (
                            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                              {skills.map((skill, index) => {
                                const [skillName, years] = skill.split(" ");
                                return (
                                  <div key={index} style={{ width: "48%", padding: "5px 0" }}>
                                    <span >
                                      {skill}</span>
                                    {/* {years ? years : "(1)"} */}
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <p>No skills listed.</p>
                          )}

                          {/* Total Experience */}
                          <h3 style={{ color: "#444", borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
                            Total Experience
                          </h3>
                          <p>{totalExperience ? `${totalExperience} years` : "N/A"}</p>

                          {/* Annotations (Links) */}
                          {annotations?.length > 0 ? (
                            <>
                              <h2 style={{ color: "#444", borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
                                Links
                              </h2>
                              <ul>
                                {annotations.map((ann, i) => (
                                  <li key={i}>
                                    <a href={ann?.url} target="_blank" rel="noopener noreferrer">
                                      {ann?.url}
                                    </a>
                                  </li>
                                ))}
                              </ul>
                            </>
                          ) : (
                            <p>No additional links provided.</p>
                          )}

                          {/* LinkedIn & GitHub */}
                          <h3 style={{ color: "#444", borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
                            Accounts
                          </h3>
                          <p>
                            {personalInformation?.linkedin && (
                              <a
                                href={personalInformation?.linkedin}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: "blue", textDecoration: "underline" }}
                              >
                                LinkedIn
                              </a>
                            )}
                            {personalInformation?.github && (
                              <span>
                                {" "}
                                |{" "}
                                <a
                                  href={personalInformation?.github}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  GitHub
                                </a>
                              </span>
                            )}
                          </p>
                        </div>
                      </div>
                      <div className="btn-container-resume">
                        <button
                          className="close-btn-popup edit-btn-popup"
                          onClick={() => dispatched(setViewPrintableResume(!viewPrintableResume))}
                        >
                          View Printable
                        </button>
                        <button
                          className="close-btn-popup edit-btn-popup"
                          onClick={() => setShowComingSoon(true)}
                        >
                          Edit Your Resume
                        </button>
                      </div>
                    </div>
                  )}



                  {editResume && (
                    <div className="edit-popup-form-container">
                      <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                        <MdClose style={{ cursor: "pointer" }} onClick={() => { dispatched(setShowResume(!showResume)); dispatched(setEditResume(!editResume)); }} />
                      </div>
                      <h2>Edit Resume</h2>

                      {/* Personal Information */}
                      <label>Name:</label>
                      <input type="text" value={editedInfo?.name} onChange={(e) => handleChange(e, "name")} />
                      <label>Email:</label>
                      <input type="email" value={editedInfo?.email} onChange={(e) => handleChange(e, "email")} />
                      <label>Phone:</label>
                      <input type="text" value={editedInfo?.phone} onChange={(e) => handleChange(e, "phone")} />
                      <label>Location:</label>
                      <input type="text" value={editedInfo?.location} onChange={(e) => handleChange(e, "location")} />

                      {/* Title & Summary */}
                      <label>Title:</label>
                      <input type="text" value={ResumeData.data.title} onChange={(e) => handleChange(e, "title")} />
                      <label>Summary:</label>
                      <textarea value={summary || "N/A"} onChange={(e) => handleChange(e, "summary")} style={{ width: "100%" }} />

                      {/* Work Experience */}
                      <h3>Work Experience</h3>
                      {editedExperiences.map((exp, index) => (
                        <div key={index}>
                          <input type="text" value={exp.company} onChange={(e) => handleExperienceChange(index, "company", e.target.value)} placeholder="Company" />
                          <input type="text" value={exp.startYear} onChange={(e) => handleExperienceChange(index, "startYear", e.target.value)} placeholder="Start Year" />
                          <input type="text" value={exp.endYear} onChange={(e) => handleExperienceChange(index, "endYear", e.target.value)} placeholder="End Year" />
                          <textarea value={exp.jd} onChange={(e) => handleExperienceChange(index, "jd", e.target.value)} placeholder="Job Description" style={{ width: "100%" }} />
                        </div>
                      ))}
                      <button onClick={addExperience}>Add Experience</button>

                      {/* Education */}
                      <h3>Education</h3>
                      {editedEducation.map((edu, index) => (
                        <div key={index}>
                          <input type="text" value={edu.school} onChange={(e) => handleEducationChange(index, "school", e.target.value)} placeholder="School" />
                          <input type="text" value={edu.year} onChange={(e) => handleEducationChange(index, "year", e.target.value)} placeholder="Year" />
                        </div>
                      ))}
                      <button onClick={addEducation}>Add Education</button>

                      {/* Skills */}
                      <h3>Skills</h3>
                      <input type="text" value={editedSkills.join(", ")} onChange={handleSkillsChange} placeholder="Skills (comma separated)" />
                      <button onClick={addSkill}>Add Skill</button>

                      {/* Total Experience */}
                      <h3>Total Experience</h3>
                      <input type="text" value={editedInfo.totalExperience} onChange={(e) => handleChange(e, "totalExperience")} placeholder="Total Experience (years)" />

                      {/* Links */}
                      <h3>Links</h3>
                      {/* {editedAnnotations.map((ann, index) => (
                        <div key={index}>
                          <input type="text" value={ann.url} onChange={(e) => handleAnnotationChange(index, "url", e.target.value)} placeholder="Link URL" />
                        </div>
                      ))}
                      <button onClick={addAnnotation}>Add Link</button> */}

                      {/* Accounts */}
                      <h3>Accounts</h3>
                      <label>LinkedIn:</label>
                      <input type="text" value={editedInfo.linkedin} onChange={(e) => handleChange(e, "linkedin")} placeholder="LinkedIn Profile" />
                      <label>GitHub:</label>
                      <input type="text" value={editedInfo.github} onChange={(e) => handleChange(e, "github")} placeholder="GitHub Profile" />

                      {/* <button onClick={() => setShowComingSoon(true)}>Save</button> */}
                      <button onClick={onSave}>Save</button>
                    </div>
                  )}

                  <button className="job-form-button" onClick={searchJob}>
                    Search Jobs
                  </button>
                </div>
              )}

              {/* show resume view or printable here  */}
              {viewPrintableResume && (
                <div className="popup-overlay">
                  <div className="popup-form-container">
                    <div className="btn-container-resume">
                      <button
                        className="close-btn-popup"
                        onClick={() => {
                          dispatched(setViewPrintableResume(false));
                        }}
                      >
                        <MdClose />
                      </button>
                    </div>
                    <div className="popup-form2">
                      <div
                        className="popup-main-container-body"
                        style={{
                          margin: "auto",
                          fontFamily: "Arial, sans-serif",
                          border: "1px solid #ddd",
                          borderRadius: "10px",
                          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        {/* Personal Info */}
                        <h1 style={{ textAlign: "center", color: "#333" }}>
                          {personalInformation?.name || "N/A"}
                        </h1>
                        <p style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <FaEnvelope style={{ marginRight: "5px", color: "black" }} />
                          <a href={`mailto:${personalInformation?.email}`} style={{ marginRight: "5px" }}>
                            {personalInformation?.email || "N/A"}
                          </a>
                          | <FaPhone style={{ margin: "0px 5px", color: "black" }} /> {personalInformation?.phone || "N/A"}
                        </p>
                        <p style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                          <FaMapMarkerAlt style={{ marginRight: "5px", color: "#EA4335" }} /> {personalInformation?.location || "N/A"}
                        </p>

                        {/* Title and Summary */}
                        <h3 style={{ color: "#444", borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
                          Title
                        </h3>
                        <p>{ResumeData?.data?.title || "N/A"}</p>

                        <h3 style={{ color: "#444", borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
                          Summary
                        </h3>
                        {summary ? (
                          <p
                            style={{ lineHeight: "1.6", padding: "0.5rem 0" }}
                            dangerouslySetInnerHTML={{
                              __html: "• " + summary.split("\n").join("<br /><br />• "),
                            }}
                          />
                        ) : (
                          <p>N/A</p>
                        )}

                        {/* Experience */}
                        <h3 style={{ color: "#444", borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
                          Work Experience
                        </h3>
                        {experiences
                          ?.filter((exp) => exp.company || exp.jd)
                          .map((exp, index) => (
                            <div key={index} style={{ marginBottom: "20px" }}>
                              <h4
                                style={{
                                  color: "#444",
                                  fontWeight: "bold",
                                  marginBottom: "0px",
                                  fontSize: "16px",
                                }}
                              >
                                {exp.company && exp.company.trim() ? exp.company : "Company Name Not Provided"}
                              </h4>
                              <p>
                                <strong>Duration:</strong> {exp?.startYear || "N/A"} - {exp?.endYear || "N/A"}
                              </p>
                              {exp.jd ? (
                                <p
                                  style={{ lineHeight: "1.6", padding: "0.5rem 0" }}
                                  dangerouslySetInnerHTML={{
                                    __html: "• " + exp.jd.split("\n").join("<br /><br />• "),
                                  }}
                                />
                              ) : (
                                <p>N/A</p>
                              )}
                            </div>
                          ))}

                        {/* Education */}
                        <h3 style={{ color: "#444", borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
                          Education
                        </h3>
                        {education?.length > 0 ? (
                          education.map((edu, index) => (
                            <p key={index}>
                              <strong>{edu?.school || "N/A"}</strong> - {edu?.year || "N/A"}
                            </p>
                          ))
                        ) : (
                          <p>No education details provided.</p>
                        )}

                        {/* Skills */}
                        <h3 style={{ color: "#444", borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
                          Skills
                        </h3>
                        {skills?.length > 0 ? (
                          <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}>
                            {skills.map((skill, index) => {
                              const [skillName, years] = skill.split(" ");
                              return (
                                <div key={index} style={{ width: "48%", padding: "5px 0" }}>
                                  <span >
                                    {skill}</span>
                                  {/* {years ? years : "(1)"} */}
                                </div>
                              );
                            })}
                          </div>
                        ) : (
                          <p>No skills listed.</p>
                        )}

                        {/* Total Experience */}
                        <h3 style={{ color: "#444", borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
                          Total Experience
                        </h3>
                        <p>{totalExperience ? `${totalExperience} years` : "N/A"}</p>

                        {/* Annotations (Links) */}
                        {annotations?.length > 0 ? (
                          <>
                            <h2 style={{ color: "#444", borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
                              Links
                            </h2>
                            <ul>
                              {annotations.map((ann, i) => (
                                <li key={i}>
                                  <a href={ann?.url} target="_blank" rel="noopener noreferrer">
                                    {ann?.url}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <p>No additional links provided.</p>
                        )}

                        {/* LinkedIn & GitHub */}
                        <h3 style={{ color: "#444", borderBottom: "2px solid #ddd", paddingBottom: "5px" }}>
                          Accounts
                        </h3>
                        <p>
                          {personalInformation?.linkedin && (
                            <a
                              href={personalInformation?.linkedin}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{ color: "blue", textDecoration: "underline" }}
                            >
                              LinkedIn
                            </a>
                          )}
                          {personalInformation?.github && (
                            <span>
                              {" "}
                              |{" "}
                              <a
                                href={personalInformation?.github}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                GitHub
                              </a>
                            </span>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="btn-container-resume">
                      <button className="close-btn-popup edit-btn-popup" onClick={() => setShowComingSoon(true)}>
                        Print Resume
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {showPopup && (
                <div className="popup-overlay">
                  <div className="popup-form">
                    <div className="close-btn-popup-signup">
                      <MdClose
                        onClick={closePopup}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                    <div className="signup-avatar">
                      <FaUserCircle size={60} className="avatar-icon" />
                      <p style={{ marginTop: "10px", fontSize: "14px", color: "#555", textAlign: "center" }}>
                        For privacy purposes, please sign up
                      </p>
                    </div>
                    <div className="signup-methods">
                      <button
                        className="google-signup"
                        onClick={handleGoogleLogin}
                      >
                        {" "}
                        <img
                          src={Google}
                          alt=""
                          style={{ marginRight: "5px", width: "25px" }}
                        />{" "}
                        Continue with Google
                      </button>
                      <button className="facebook-signup">
                        <img
                          src={Facebook}
                          alt=""
                          style={{ marginRight: "5px", width: "25px" }}
                        />{" "}
                        Continue with Facebook
                      </button>
                    </div>

                    {/* OR Separator with Lines */}
                    <div className="separator">
                      <div className="line"></div>
                      <span>or</span>
                      <div className="line"></div>
                    </div>


                    <input
                      type="email"
                      placeholder="Email"
                      className="popup-input"
                    />
                    {/* <input type="password" placeholder="Password" className="popup-input" /> */}
                    <div className="password-field">
                      <input
                        type={showPassword.password ? "text" : "password"}
                        placeholder="Password"
                        className="popup-input"
                      />
                      <button
                        className="password-toggle"
                        onClick={() =>
                          setShowPassword((prev) => ({
                            ...prev,
                            password: !prev.password,
                          }))
                        }
                      >
                        {showPassword.password ? <FaEyeSlash /> : <FaEye />}
                      </button>
                    </div>
                    <button className="signup-btn">Sign Up</button>
                  </div>
                </div>
              )}
              {showApplyType === false && (
                <>
                  <div className="job-form-header">
                    <button
                      className="back-btn"
                      onClick={() => {
                        setShowApplyType(null);
                        setOptionsForJob(true);
                      }}
                    >
                      <MdArrowBack size={24} style={{ color: "white" }} />
                    </button>
                  </div>
                  <h2>Coming Soon</h2>
                </>
              )}
            </div>
          )}

        </div>

        {showSharePopup && (
          <div className="popup">
            <button
              className="close-btn"
              onClick={() => setShowSharePopup(false)}
            >
              ×
            </button>
            <h4
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              Share To <IoShareSocial style={{ marginLeft: "5px" }} />
            </h4>
            <div className="social-icons">
              {socialPlatforms.map(({ name, url, icon }, index) => (
                <a
                  key={`${name}-${index}`}
                  href={urls[url]}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => handleButtonClick(messageId, "share")}
                >
                  {icon}
                </a>
              ))}
            </div>
          </div>
        )}
      </>
    )
  );
};

export default Message;
